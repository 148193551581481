import Card from "../Common/Card";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../Util/LoadingSpinner";
import { useParams } from "react-router-dom";
import Toggle from "./Toggle";
import SearchName from "./SearchName";

const RoleAssignSearch = ({ searchQuery }) => {
  const [resultCount, setResultCount] = useState(0);
  const [searchFiles, setSearchFiles] = useState([]);
  const [isSearchFiles, setIsSearchFiles] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [num, setNum] = useState(1);
  const [limit, setLimit] = useState(8);

  const [uid, setUid] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setLimit(8);
    fetch("https://reuseportalbackendservice.cherrywork.com/getUserByName/" + searchQuery)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setResultCount(data.length);
        setSearchFiles(data);

        setUid(searchFiles.uid);

        setIsSearchFiles(false);
        setIsLoading(false);
      });
  }, [searchQuery, uid]);

  return (
    <div className="something col-md-12">
      {searchFiles && (
        <span>
          <SearchName searchFiles={searchFiles} />
        </span>
      )}
    </div>
  );
};

export default RoleAssignSearch;
