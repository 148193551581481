import { Link } from "react-router-dom";
import NumToString from "../Util/NumToString";

const Card = ({ component }) => {
  return (
    <div className="cardGroup col-md-6">
      <div className="individual-card">
        <div className="[ panel panel-default ] panel-google-plus">
          <div className="panel-heading">
            <img
              className="[ img-circle pull-left ]"
              src={"data:image/png;base64," + component.imageUrl}
              width="45px"
              height="auto"
              alt="UserImg"
            />
            <Link
              className="cardUser"
              to={"/home/profile/" + component.userEmail}
            >
              <h3>{component.username}</h3>
            </Link>
            <h5>
              <span>
                Uploaded On:{" "}
                { component.date.slice(7, 10) +
                  " " +
                  component.date.slice(3, 7) +
                  " " +
                  component.date.slice(10, 15)}
              </span>
            </h5>
          </div>
          <div className="panel-body">
            <h2>
              <Link to={"/home/component/" + component.component_id}>
                <abbr title={component.component_name}>
                  {component.component_name.length > 27
                    ? component.component_name.slice(0, 27) + ".."
                    : component.component_name.slice(0, 27) + ""}
                </abbr>
              </Link>
              &nbsp;&nbsp;&nbsp;
              {(sessionStorage.getItem("loggedInEmail") ===
                component.userEmail ||
                sessionStorage.getItem("loggedInRole") === "Admin") && (
                <>
                  <Link to={"/home/edit/" + component.component_id}>
                    <i className="fa fa-edit"></i>
                  </Link>
                  &nbsp;&nbsp;
                  <Link to={"/home/delete/" + component.component_id}>
                    <i className="fa fa-trash text-danger"></i>
                  </Link>
                  &nbsp;&nbsp;
                  <Link to={"/home/chart/" + component.component_id}>
                    <i className="fa fa-bar-chart"></i>
                  </Link>
                </>
              )}
            </h2>
            <div className="tags pb-3">
              {component.tags
                .trim()
                .split(",")
                .map((itag) => (
                  <span key={itag} className="badge badge-pill">
                    {itag}
                  </span>
                ))}
            </div>
            {/* <p>{component.description}</p> */}
          </div>
          <div className="panel-footer">
            <div className="row">
              <div className="col-md-3 d-flex justify-content-center">
                <button type="button" className="[ btn btn-default ] ">
                  <i className="fa fa-heart text-danger"></i>
                  <p className="m-0">
                    <small>
                      <NumToString points={component.nlikes}></NumToString>
                    </small>
                  </p>
                </button>
              </div>
              <div className="col-md-3 d-flex justify-content-center">
                <button type="button" className="[ btn btn-default ] m-auto">
                  <i className="fa fa-comment text-primary"></i>
                  <p className="m-0">
                    <small>
                      <NumToString points={component.ncomments}></NumToString>
                    </small>
                  </p>
                </button>
              </div>
              <div className="col-md-3 d-flex justify-content-center">
                <button type="button" className="[ btn btn-default ] hidden-sm">
                  <i className="fa fa-cloud-download text-info"></i>
                  <p className="m-0 ">
                    <small>
                      <NumToString points={component.ndownloads}></NumToString>
                    </small>
                  </p>
                </button>
              </div>
              <div className="col-md-3 d-flex justify-content-center">
                <button
                  type="button"
                  className="[ btn btn-default ] hidden-sm "
                  id="share"
                >
                  <i className="fa fa-share text-success"></i>
                  <p className="m-0">
                    <small>
                      <NumToString points={component.nshares}></NumToString>
                    </small>
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
