import { Link } from "react-router-dom";
import NumToString from "../Util/NumToString";

const ListItem = ({ component }) => {
  return (
    <div className="col-md-12">
      <div className="row list-item">
        <div className="col-md-1 img-col d-flex justify-content-center">
          <Link to={"/home/profile/" + component.userEmail}>
            <img
              src={"data:image/png;base64," + component.imageUrl}
              alt=""
              className="img-fluid rounded-circle m-auto list-img"
            />
          </Link>
        </div>
        <div className="col-md-7 d-flex text-left align-items-center list-name">
          <Link
            to={"/home/component/" + component.component_id}
            className="list-item-name"
          >
            <abbr title={component.component_name}>
              {
                <p className="m-auto">
                  {component.component_name.length > 31
                    ? component.component_name.slice(0, 31) + ".."
                    : component.component_name.slice(0, 31) + ""}
                  <span className="badge badge-pill badge-warning text-light float-right mt-1">
                    {component.tags.trim().split(",")[0]}
                  </span>
                  {(sessionStorage.getItem("loggedInEmail") ===
                    component.userEmail ||
                    sessionStorage.getItem("loggedInRole") === "Admin") && (
                    <>
                      &nbsp;&nbsp;
                      <span>
                        <Link to={"/home/edit/" + component.component_id}>
                          <i className="fa fa-edit"></i>
                        </Link>
                        &nbsp;&nbsp;
                        <Link to={"/home/delete/" + component.component_id}>
                          <i className="fa fa-trash text-danger"></i>
                        </Link>
                        &nbsp;&nbsp;
                        <Link to={"/home/chart/" + component.component_id}>
                          <i className="fa fa-bar-chart"></i>
                        </Link>
                      </span>
                    </>
                  )}
                </p>
              }
            </abbr>
          </Link>
        </div>
        <div className="social-btns col-md-4 d-flex align-items-center justify-content-center">
          <div className="row">
            <div className="col-md-3 d-flex align-items-center">
              <p className="m-auto">
                <i className="fa fa-heart text-danger">
                  &nbsp;
                  <span className="text-dark">
                    <NumToString points={component.nlikes}></NumToString>
                  </span>
                </i>
              </p>
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <p className="m-auto">
                <i className="fa fa-comment text-primary">
                  &nbsp;
                  <span className="text-dark">
                    <NumToString points={component.ncomments}></NumToString>
                  </span>
                </i>
              </p>
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <p className="m-auto">
                <i className="fa fa-download text-info">
                  &nbsp;
                  <span className="text-dark">
                    <NumToString points={component.ndownloads}></NumToString>
                  </span>
                </i>
              </p>
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <p className="m-auto">
                <i className="fa fa-share text-success">
                  &nbsp;
                  <span className="text-dark">
                    <NumToString points={component.nshares}></NumToString>
                  </span>
                </i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
