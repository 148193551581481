import NumToString from "../Util/NumToString";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Monthlead = () => {
  const [leadProfiles, setLeadProfiles] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 6;
  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    fetch("https://reuseportalbackendservice.cherrywork.com/getleaderinMonth")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setLeadProfiles(data.slice().reverse());
      });
  };

  const onClickPaginate = (number, action) => {
    console.log((pageNumber - 1) * pageSize, pageSize * pageNumber);
    console.log(
      leadProfiles?.slice((pageNumber - 1) * pageSize, pageSize * pageNumber)
    );
    if (leadProfiles?.length === 0) {
      return;
    }
    if (action === "NEXT") {
      if (pageNumber === leadProfiles?.length) {
        return;
      }
      setPageNumber(pageNumber + 1);
    }
    if (action === "PREV") {
      if (pageNumber === 1) {
        return;
      }
      setPageNumber(pageNumber - 1);
    }
    if (action === "DIRECT") {
      setPageNumber(number);
    }
  };
  return (
    <div className="Quarterboard">
      <ul className="list-group list-group-flush" style={{ maxHeight: "64vh" }}>
        {leadProfiles &&
          leadProfiles
            ?.slice((pageNumber - 1) * pageSize, pageSize * pageNumber)
            .map((profile, index) =>
              profile.points === "0" ? (
                ""
              ) : (
                <Link key={index} to={"/home/profile/" + profile.email}>
                  <li className="list-group-item">
                    <div className="row ">
                      <div className="col-md-3 m-auto">
                        {profile.Date.split("-")[0].slice(0, 3) +
                          "-" +
                          profile.Date.split("-")[1]}
                      </div>
                      <div className="col-md-6 m-auto">
                        <img
                          src={"data:image/png;base64," + profile.imageUrl}
                          alt=""
                          className="secPic rounded-circle m-auto pl-0 pr-0"
                        />{" "}
                        &nbsp; {profile.name}
                      </div>
                      <div className="col-md-3 m-auto pl-0 pr-0">
                        <NumToString points={profile.points}></NumToString>
                        {" Points"}
                      </div>
                    </div>
                  </li>
                </Link>
              )
            )}
      </ul>
      {Boolean(leadProfiles.length) && (
        <nav
          style={{
            margin: "1rem 0",
            float: "right",
            fontSize: "0.9rem",
            fontWeight: "bold",
            fontColor: "#0D47A1",
          }}
        >
          <ul className="pagination" style={{ borderRadius: "20px" }}>
            <li className="page-item">
              <button
                onClick={() => onClickPaginate(pageNumber, "PREV")}
                disabled={pageNumber === 1}
                className="page-link"
              >
                Previous
              </button>
            </li>
            {Array.from(
              {
                length:
                  Math.ceil(leadProfiles?.length / pageSize) === 0
                    ? 1
                    : Math.floor(leadProfiles?.length / pageSize) + 1,
              },
              (_, i) => i + 1
            ).map((num) => (
              <li className="page-item">
                <button
                  onClick={() => onClickPaginate(num, "DIRECT")}
                  className="page-link"
                  style={{
                    color: pageNumber === num ? "#0D47A1" : "#2196F3",
                    fontWeight: pageNumber === num ? "bold" : "thin",
                  }}
                >
                  {num}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                disabled={pageNumber === Math.ceil(leadProfiles?.length/pageSize)}
                className="page-link"
                onClick={() => onClickPaginate(pageNumber, "NEXT")}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Monthlead;
