import Card from "../Common/Card";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LineChart from "./Analizecomponents";

const MyActivity = () => {

    return (
    
       <div className="activity">
       <LineChart/>
       </div>
     );
}
 
export default MyActivity;