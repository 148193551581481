import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CardSlide from "./CardSlide";
import Comments from "./Comments";
import { Link } from "react-router-dom";

const SingleComponent = () => {
  const { id } = useParams();

  const [singleComponent, setSingleComponent] = useState(null);
  const [userComment, setUserComment] = useState("");

  const [nLikes, setNLikes] = useState(0);
  const [nComments, setNComments] = useState(0);
  const [nShares, setNShares] = useState(0);
  const [nDownloads, setNDownloads] = useState(0);

  useEffect(() => {
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/cardDetails/component/cid/" +
        id
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setSingleComponent(data);

        setNLikes(data.nlikes);
        setNComments(data.ncomments);
        setNShares(data.nshares);
        setNDownloads(data.ndownloads);
      });
  }, []);

  const handleDownload = (type) => {
    setNDownloads(parseInt(nDownloads) + 1);
    if (type === "jar") {
      window.open(
        "https://reuseportalbackendservice.cherrywork.com/artifacts/download/jar/id/" +
          id,
        "_blank"
      );
    } else if (type === "src") {
      window.open(
        "https://reuseportalbackendservice.cherrywork.com/artifacts/download/source/id/" +
          id,
        "_blank"
      );
    } else if (type === "doc") {
      window.open(
        "https://reuseportalbackendservice.cherrywork.com/artifacts/download/document/id/" +
          id,
        "_blank"
      );
    } else if (type === "all") {
      if (singleComponent.jarname === null) {
        window.open(
          "https://reuseportalbackendservice.cherrywork.com/artifacts/download/document/id/" +
            id,
          "_blank"
        );
        window.open(
          "https://reuseportalbackendservice.cherrywork.com/artifacts/download/source/id/" +
            id,
          "_blank"
        );
      } else if (singleComponent.srcname === null) {
        window.open(
          "https://reuseportalbackendservice.cherrywork.com/artifacts/download/document/id/" +
            id,
          "_blank"
        );
        window.open(
          "https://reuseportalbackendservice.cherrywork.com/artifacts/download/jar/id/" +
            id,
          "_blank"
        );
      } else {
        window.open(
          "https://reuseportalbackendservice.cherrywork.com/artifacts/download/document/id/" +
            id,
          "_blank"
        );
        window.open(
          "https://reuseportalbackendservice.cherrywork.com/artifacts/download/jar/id/" +
            id,
          "_blank"
        );
        window.open(
          "https://reuseportalbackendservice.cherrywork.com/artifacts/download/source/id/" +
            id,
          "_blank"
        );
      }
    } else {
      return;
    }
    var uid = sessionStorage.getItem("loggedInId");
    var cid = id;
    var action = "Download";
    var comment = null;
    var date = new Date();
    const payload = { uid, cid, action, comment, date };
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/addSocialTableDetails",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setNDownloads(data.count);
      });
  };

  const handleShare = () => {
    var copyText = "https://reuseportal.azurewebsites.net/home/component/" + id;
    navigator.clipboard.writeText(copyText);
    alert("Link Copied to Clipboard");
    setNShares(parseInt(nShares) + 1);

    var uid = sessionStorage.getItem("loggedInId");
    var cid = id;
    var action = "Share";
    var comment = null;
    var date = new Date();
    const payload = { uid, cid, action, comment, date };
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/addSocialTableDetails",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setNShares(data.count);
      });
  };

  const postComment = (e) => {
    e.preventDefault();
    setNComments(parseInt(nComments) + 1);
    var uid = sessionStorage.getItem("loggedInId");
    var cid = id;
    var action = "Comment";
    var comment = userComment;
    var date = new Date();
    const payload = { uid, cid, action, comment, date };
    const url =
      "https://reuseportalbackendservice.cherrywork.com/addSocialTableDetails";
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setUserComment("");
        setNComments(data.count);
      });
  };

  const handleLike = (e) => {
    e.preventDefault();
    setNLikes(parseInt(nLikes) + 1);
    var uid = sessionStorage.getItem("loggedInId");
    var cid = id;
    var action = "Like";
    var comment = userComment;
    var date = new Date();
    const payload = { uid, cid, action, comment, date };
    const url =
      "https://reuseportalbackendservice.cherrywork.com/addSocialTableDetails";
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setNLikes(data.count);
      });
  };

  const handleComment = () => {
    document.getElementById("toggle-comments").click();
  };

  return (
    <div className="single-component">
      <div className="row">
        {singleComponent && (
          <div className="card">
            <div className="card-body">
              <div className="[ panel panel-default ] panel-google-plus">
                <div className="panel-heading">
                  <img
                    className="[ img-circle pull-left ]"
                    src={"data:image/png;base64," + singleComponent.imageUrl}
                    alt="UserImg"
                  />
                  <Link
                    className="singleCardUser"
                    to={"/home/profile/" + singleComponent.userEmail}
                  >
                    <h3>{singleComponent.username}</h3>
                  </Link>
                  <small>
                    Uploaded On:{" "}
                    {singleComponent.date.slice(7, 10) +
                      " " +
                      singleComponent.date.slice(3, 7) +
                      " " +
                      singleComponent.date.slice(10, 15)}
                  </small>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col col-md-7">
                      <h2>{singleComponent.component_name}</h2>
                      <div className="tags pb-3">
                        {singleComponent.tags.split(",").map((itag) => (
                          <span key={itag} className="badge badge-pill">
                            {itag}
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="col col-md-5 m-auto">
                      <div className="row social-buttons">
                        <div className="col-md-3">
                          <button className="btn social" onClick={handleLike}>
                            <div className="row">
                              <div className="col col-md-12">
                                <i className="fa fa-heart text-danger"></i>
                              </div>
                              <div className="col col-md-12">{nLikes}</div>
                            </div>
                          </button>
                        </div>
                        <div className="col-md-3">
                          <button
                            className="btn social"
                            onClick={handleComment}
                          >
                            <div className="row">
                              <div className="col col-md-12">
                                <i className="fa fa-comment text-primary"></i>
                              </div>
                              <div className="col col-md-12">{nComments}</div>
                            </div>
                          </button>
                        </div>
                        <div className="col-md-3">
                          <button
                            className="btn social"
                            onClick={() => handleDownload("all")}
                          >
                            {" "}
                            <div className="row">
                              <div className="col col-md-12">
                                <i className="fa fa-cloud-download text-info"></i>
                              </div>
                              <div className="col col-md-12">{nDownloads}</div>
                            </div>
                          </button>
                        </div>
                        <div className="col-md-3">
                          <button className="btn social" onClick={handleShare}>
                            <div className="row">
                              <div className="col col-md-12">
                                <i className="fa fa-share text-success"></i>
                              </div>
                              <div className="col col-md-12"> {nShares}</div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-md-12 text-justify">
                      <p>{singleComponent.description}</p>
                    </div>
                  </div>
                  <div className="row file-components">
                    <div className="col-md-4">
                      <p className="h6 text-center file-stats-head">
                        Executable File
                      </p>
                      <div className="card text-center m-auto">
                        <div className="card-body">
                          {singleComponent.jarname && (
                            <div
                              className="component-content"
                              onClick={() => handleDownload("jar")}
                            >
                              <div className="card-title top-component-name">
                                {singleComponent.jarname}
                              </div>
                              <div className="btn">
                                <span>
                                  <i className="fa fa-file-text-o fa-2x"></i>
                                </span>
                              </div>
                            </div>
                          )}
                          {!singleComponent.jarname && (
                            <div className="component-content">
                              <div className="card-title top-component-name">
                                Not Available
                              </div>
                              <div className="btn">
                                <span>
                                  <i className="fa fa-file-text-o fa-2x"></i>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p className="h6 text-center file-stats-head">
                        Source Code
                      </p>
                      <div className="card text-center m-auto">
                        <div className="card-body">
                          {singleComponent.srcname && (
                            <div
                              className="component-content"
                              onClick={() => handleDownload("src")}
                            >
                              <div className="card-title top-component-name">
                                {singleComponent.srcname}
                              </div>

                              <div className="btn">
                                <span>
                                  <i className="fa fa-file-archive-o fa-2x"></i>
                                </span>
                              </div>
                            </div>
                          )}

                          {!singleComponent.srcname && (
                            <div className="component-content">
                              <div className="card-title top-component-name">
                                Not Available
                              </div>

                              <div className="btn">
                                <span>
                                  <i className="fa fa-file-archive-o fa-2x"></i>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p className="h6 text-center file-stats-head">
                        Documentation
                      </p>
                      <div className="card text-center m-auto">
                        <div className="card-body">
                          <div
                            className="component-content"
                            onClick={() => handleDownload("doc")}
                          >
                            <div className="card-title top-component-name">
                              {singleComponent.docname}
                            </div>
                            <div className="btn">
                              <span>
                                <i className="fa fa-file-pdf-o fa-2x"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-footer">
                  <div className="row">
                    <div className="col-md-12 mt-4">
                      {sessionStorage.getItem("loggedInEmail") !==
                        singleComponent.userEmail && (
                        <>
                          <form className="post-comment" onSubmit={postComment}>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Post a comment"
                                value={userComment}
                                onChange={(e) => setUserComment(e.target.value)}
                              />
                              <div className="input-group-append">
                                <button
                                  type="submit"
                                  className="btn btn-outline-secondary"
                                >
                                  Post
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <Comments cid={id} nComments={nComments} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <div className="row similar-component">
                <p className="h3 mb-4">Similar Components</p>
                <CardSlide/>
            </div> */}
    </div>
  );
};

export default SingleComponent;
