import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {Chart as ChartJS,ArcElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler} from  'chart.js';

  ChartJS.register(

      ArcElement,
      CategoryScale,
      LinearScale,
      LineElement,
      PointElement,
      Title,
      Tooltip,
      Legend,
      Filler
    );

    const LineChart = () => {

  
      const [componentname, setComponentname] = useState(null)
      const [likedata, setLikedata] = useState(null)
      const [sharedata, setSharedata] = useState(null)
      const [commentdata, setCommentdata] = useState(null)
      const [downloaddata, setDownloaddata] = useState(null)


      const lineChartdata = {
        labels: componentname,
        datasets: [
          {
            data: likedata,
            label: "Likes",
            borderColor: "#dc3545",
            backgroundColor: "#dc3545",
          },
          {
            data: commentdata,
            label: "Comments",
            borderColor: "#007bff",
            backgroundColor: "#007bff",
          },
          {
            data: downloaddata,
            label: "Downloads",
            borderColor: "#17a2b8",
            backgroundColor: "#17a2b8",
          },
          {
            data: sharedata,
            label: "Shares",
            borderColor: "#28a745",
            backgroundColor: "#28a745",
          }
        ]
      }
      useEffect(() => {

        const C_name = []
        const like = []
        const comment =[]
        const download = []
        const share =[]

        fetch('https://reuseportalbackendservice.cherrywork.com/cardDetails/component/email/'+sessionStorage.getItem("loggedInEmail"))
        .then(res => {
          return res.json()
        })
        .then(data =>{
          data.map((a) =>{
            C_name.push(a.component_name);
            like.push(a.nlikes);
            comment.push(a.ncomments);
            share.push(a.nshares);
            download.push(a.ndownloads)
          })
          /*console.log(C_name)
          console.log(like)
          console.log(comment)
          console.log(share)
          console.log(download)*/
          setComponentname(C_name);
          setLikedata(like);
          setCommentdata(comment);
          setSharedata(share);
          setDownloaddata(download)
        })
        
      },[]
      )
  
      return ( 
      <div className="improvement card">
        <div className="card-body">
        <Line
          type="line"
          options={{
            title: {
              display: true,
              text: "Showing improvement",
              fontSize: 15,
              position: "buttom"
               },
            legend: {
               display: true, //Is the legend shown?
               position: "top" //Position of the legend.
               }
          }}
          data={lineChartdata}
        />
        </div>
      </div> );
    }
     
    export default LineChart;