import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../Util/LoadingSpinner";

const Profile = () => {
  const { email } = useParams();
  const [singleProfile, setSingleProfile] = useState(null);
  const [topComponents, setTopComponents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/userDetails/email/" + email
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (sessionStorage.getItem("loggedInEmail") === data.email) {
          sessionStorage.setItem("loggedInImage", data.imageUrl);
        }
        setSingleProfile(data);
        setIsLoading(false);
      });
  }, [email]);

  useEffect(() => {
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/userTopComponentDetails/email/" +
        email
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setTopComponents(data);
      });
  }, [email]);

  return (
    <div className="profile">
      <p className="h2 headtxt">Profile</p>
      {isLoading ? (
        <LoadingSpinner className="m-auto" />
      ) : (
        singleProfile && (
          <div className="profile-details">
            <div className="row user-details">
              <div className="col-md-3 d-flex justify-content-center">
                <img
                  src={"data:image/png;base64," + singleProfile.imageUrl}
                  alt=""
                  className="rounded-circle profile-img"
                />
              </div>
              <div className="col-md-9">
                <p className="h4 profile-name">
                  {singleProfile.name} | <small>{singleProfile.position}</small>
                </p>
                <p className="profile-info">
                  <div>
                    <span className="email">
                      <i className="fa fa-envelope "></i>&nbsp;&nbsp;
                      {singleProfile.email}
                    </span>
                    {singleProfile.phone_num && (
                      <span className="phonenumber">
                        <i className="fa fa-phone"></i>&nbsp;&nbsp;
                        {singleProfile.phone_num}
                      </span>
                    )}
                  </div>
                  <div>
                    {singleProfile.linkedin_url && (
                      <a href={singleProfile.linkedin_url} target="_blank">
                        <span className="linkedin">
                          <i className="fa fa-linkedin"></i>&nbsp;&nbsp;LinkedIn
                        </span>
                      </a>
                    )}
                    {singleProfile.github_url && (
                      <a
                        href={singleProfile.github_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="github">
                          <i className="fa fa-github"></i>&nbsp;&nbsp;GitHub
                        </span>
                      </a>
                    )}
                  </div>
                </p>
                <div className="profile-fields">
                  <div className="row fields-tags">
                    {singleProfile.field_of_expertise &&
                      singleProfile.field_of_expertise
                        .split(",")
                        .map((itag) => (
                          <span
                            key={itag}
                            className="badge badge-pill badge-warning"
                          >
                            {itag}
                          </span>
                        ))}
                  </div>
                </div>
                <div className="profile-desc text-justify">
                  {singleProfile.bio}
                </div>
              </div>
            </div>
            <div className="row user-stats">
              <div className="col-md-3 m-auto">
                <div className="row">
                  <div className="col-md-4 stat-icon m-auto">
                    <i className="fa fa-star "></i>
                  </div>
                  <div className="col-md-8">
                    <span>
                      <p className="stat-num">{singleProfile.user_points}</p>
                      <p className="stat-detail">Points</p>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 m-auto">
                            <div className="row">
                                <div className="col-md-4 stat-icon m-auto">
                                        <i className="fa fa-eye"></i>
                                </div>
                                <div className="col-md-8">
                                    <span>
                                        <p className="stat-num">345</p>
                                        <p className="stat-detail">Profile Views</p>
                                    </span>
                                </div>
                            </div>
                        </div> */}
              <div className="col-md-3 m-auto">
                <div className="row">
                  <div className="col-md-4 stat-icon m-auto">
                    <i className="fa fa-cloud-upload "></i>
                  </div>
                  <div className="col-md-8">
                    <span>
                      <p className="stat-num">
                        {singleProfile.NumberOfUploads}
                      </p>
                      <p className="stat-detail">Total Uploads</p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 m-auto">
                <div className="row">
                  <div className="col-md-4 stat-icon m-auto">
                    <i className="fa fa-cloud-download "></i>
                  </div>
                  <div className="col-md-8">
                    <span>
                      <p className="stat-num">
                        {singleProfile.NumberOfDownloads}
                      </p>
                      <p className="stat-detail">Total Downloads</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {topComponents && (
              <div className="row top-components">
                {topComponents.topDownloadsName && (
                  <div className="col-md-4 m-auto">
                    <p className="h6 text-center component-stats-head">
                      Most Downloaded
                    </p>
                    <div className="card text-center">
                      <div className="card-body">
                        <div className="card-title top-component-name">
                          <Link to={"/home/component/"+topComponents.topDownloadsId}>
                            {topComponents.topDownloadsName}
                          </Link>
                        </div>
                        <div className="btn btn-warning component-stats-num">
                          <span>
                            <i className="fa fa-download"></i>&nbsp;&nbsp;
                            {topComponents.topDownloads}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {topComponents.topLikedName && (
                  <div className="col-md-4 m-auto">
                    <p className="h6 text-center component-stats-head ">
                      Most Liked
                    </p>
                    <div className="card text-center">
                      <div className="card-body">
                        <div className="card-title top-component-name">
                          <Link to={"/home/component/"+topComponents.topLikedId}>
                            {topComponents.topLikedName}
                          </Link>
                        </div>
                        <div className="btn btn-warning component-stats-num">
                          <span>
                            <i className="fa fa-heart"></i>&nbsp;&nbsp;
                            {topComponents.topLiked}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {topComponents.topSharedName && (
                  <div className="col-md-4 m-auto">
                    <p className="h6 text-center component-stats-head">
                      Most Shared
                    </p>
                    <div className="card text-center ">
                      <div className="card-body">
                        <div className="card-title top-component-name">
                          <Link to={"/home/component/"+topComponents.topSharedId}>
                            {topComponents.topSharedName}
                          </Link>
                        </div>
                        <div className="btn btn-warning component-stats-num">
                          <span>
                            <i className="fa fa-share"></i>&nbsp;&nbsp;
                            {topComponents.topShared}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default Profile;
