export const msalConfig = {
  auth: {
    clientId: "ccd885d2-777c-4a8d-8d0d-546e344de2c9",
    authority: "https://login.microsoftonline.com/incturet.onmicrosoft.com",
    redirectUri: "https://reuseportal.cherrywork.com",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["User.Read"]
};

//Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};