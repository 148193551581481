
const NotificationSettings = () => {
    return ( 
        <div className="notification-settings">
                <div className="row">
                    <div className="card settings-notif">
                        <form>
                        <div className="card-body">
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" />
                                <label className="form-check-label notifs">Lorem ipsum dolor sit amet consectetur adipisicing elit. </label>
                            </div>
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" />
                                <label className="form-check-label notifs">Lorem ipsum dolor sit amet consectetur adipisicing elit. </label>
                            </div>
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" />
                                <label className="form-check-label notifs">Lorem ipsum dolor sit amet consectetur adipisicing elit. </label>
                            </div>
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" />
                                <label className="form-check-label notifs">Lorem ipsum dolor sit amet consectetur adipisicing elit. </label>
                            </div>
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" />
                                <label className="form-check-label notifs">Lorem ipsum dolor sit amet consectetur adipisicing elit. </label>
                            </div>
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" />
                                <label className="form-check-label notifs">Lorem ipsum dolor sit amet consectetur adipisicing elit. </label>
                            </div>
                                <br />
                                <button type="submit" className="btn btn-warning settings-save">Save Changes</button>                         
                            </div>
                            
                            </form>
                        </div>
                </div>  
        </div>
     );
}
 
export default NotificationSettings;