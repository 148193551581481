import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import {Chart as ChartJS,ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend} from  'chart.js';
import axios from "axios";


  ChartJS.register(

      ArcElement,
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );


const ComponentChart = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const [mailid,setMailid] = useState()
    const [chartdata, setChartdata] = useState(null)
    const [highestdata, setHighestdata] = useState(null)

    const barChartData = {
        labels: ["Like", "Share", "Comment","Downloads"],
        datasets: [
          {
            data: chartdata,
            label: "Current one",
            borderColor: "#dc3545",
            backgroundColor: "#dc3545",
            fill: true
          },
          {
            data: highestdata,
            label: "Highest one",
            borderColor: "#007bff",
            backgroundColor: "#007bff",
            fill: true
          }
        ]
      };

      // const fetchUserEmail = async () => {
      //   try {
      //     const data = (await axios.get('https://reuseportalbackendservice.cherrywork.com/cardDetails/component/cid/'+id)).data
      //     var x = [data.nlikes, data.nshares, data.ncomments, data.ndownloads]
        
      //     if (data.userEmail === sessionStorage.getItem("loggedInEmail")  || (sessionStorage.getItem("loggedInRole") === "Admin") ) {
      //         setChartdata(x)
      //     }
      //     else{
      //       // console.log("hello")
      //         navigate('/home/component/'+id);
      //     } 
      //     if(data.userEmail) {
      //       topSocialaction(data.userEmail)
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // } 

      // const topSocialaction = async (email) => {
      //   try{
      //       const data = (await axios.get("https://reuseportalbackendservice.cherrywork.com/userTopComponentDetails/email/" + email)).data
      //       var x = [data.topLiked, data.topShared, data.topComment, data.topDownloads]
      //       if (data.userEmail === sessionStorage.getItem("loggedInEmail")  || (sessionStorage.getItem("loggedInRole") === "Admin") ) {
      //           setHighestdata(x)
      //       }
      //       else{
      //           // navigate('/home/component/'+id);
      //       }
      //   } catch (error) {

      //   }

      // } 
    
    useEffect(() => {

        fetch('https://reuseportalbackendservice.cherrywork.com/cardDetails/component/cid/'+id)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            var x = [data.nlikes, data.nshares, data.ncomments, data.ndownloads]
            var y = data.userEmail
            setChartdata(x)
            setMailid(y)
        })
        console.log(mailid);

        fetch("https://reuseportalbackendservice.cherrywork.com/userTopComponentDetails/email/" + mailid)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            var x = [data.topLiked, data.topShared, data.topComment, data.topDownloads]
            setHighestdata(x)
        })
       
        // fetchUserEmail()
    },[mailid])

    // {mailid && (fetch("https://reuseportalbackendservice.cherrywork.com/userTopComponentDetails/email/" + mailid)
    // .then((res)=>{
    //     return res.json()
    // })
    // .then((data)=>{
    //     var x = [data.topLiked, data.topShared, data.topComment, data.topDownloads]
    //     setHighestdata(x)
    // }))}
    

    return(
      <div className="activitybypost">
        <p id="content-title" className="card-title">
            <span id="dashboard-title">Activity by Component &nbsp;<i className="fa fa-bar-chart"></i></span>
          </p>
        <div className="comparechart card">
          <div className="card-body">
          <Bar
              type="bar"
              width={130}
              height={50}
              options={{
                title: {
                  display: true,
                  text: "Comparision between Highest and Current",
                  fontSize: 15,
                  position: "buttom",
                },
                legend: {
                  display: true, //Is the legend shown?
                  position: "top" //Position of the legend.
                }
              }}
              data={barChartData}
            />
          </div>
        </div>
        </div>

            
    );
     
}
 
export default ComponentChart;