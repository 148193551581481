import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ComponentEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [singleComponent, setSingleComponent] = useState(null);

  const [editMsg, setEditMsg] = useState(null);

  const [username, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [componentName, setComponentName] = useState();
  const [componentTopic, setComponentTopic] = useState();
  const [componentDesc, setComponentDesc] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/cardDetails/component/cid/" +
        id
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (
          data.userEmail === sessionStorage.getItem("loggedInEmail") ||
          sessionStorage.getItem("loggedInRole") === "Admin"
        ) {
          setSingleComponent(data);
          setUserName(data.username);
          setUserEmail(data.userEmail);
          setComponentName(data.component_name);
          setComponentTopic(data.tags);
          setComponentDesc(data.description);
        } else {
          setEditMsg("Not Authorized to Edit");
        }
      });
  }, []);

  const handleUploadFile = (e) => {
    e.preventDefault();

    setIsLoading(true);
    var name = username;
    var email = userEmail;
    var component = componentName;
    var tag = componentTopic;
    var description = componentDesc;
    var updateDate = new Date();
    var updateBy = sessionStorage.getItem("loggedInEmail");

    // const formData = {name, email, component, tag, description, pom, jar, src, document};
    const url =
      "https://reuseportalbackendservice.cherrywork.com/updateFileDetails";
    const formData = new FormData();
    formData.append("cid", id);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("component", component);
    formData.append("tag", tag);
    formData.append("description", description);
    formData.append("updateDate", updateDate);
    formData.append("updateBy", updateBy);

    fetch(url, {
      method: "PUT",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === "1") {
          setIsLoading(false);
          navigate("/home/component/" + id);
        } else {
          setEditMsg("Updating Failed");
        }
      });
  };

  return (
    <div className="uploadArtefact">
      <p id="content-title" className="card-title">
        <span id="dashboard-title">
          Edit a Component &nbsp;<i className="fa fa-edit"></i>
        </span>
      </p>
      {singleComponent && (
        <form
          id="uploadFile"
          encType="multipart/form-data"
          onSubmit={handleUploadFile}
        >
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="username">Name</label>
              <input
                type="text"
                className="form-control"
                name="username"
                id="username"
                placeholder="Enter your name"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                disabled
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="userEmail">Email</label>
              <input
                type="text"
                className="form-control"
                id="userEmail"
                name="userEmail"
                placeholder="Enter your email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                disabled
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="componentName">
                Component Name{" "}
                <small className="text-light">(Max Length: 40 chars)</small>
              </label>
              <input
                type="text"
                className="form-control"
                name="componentName"
                id="componentName"
                placeholder="Enter the component name"
                value={componentName}
                onChange={(e) => setComponentName(e.target.value)}
                maxLength="40"
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="componentTopic">
                Tags{" "}
                <small className="text-light">
                  (separated by comma) (Max Length: 40 chars)
                </small>{" "}
              </label>
              <input
                type="text"
                className="form-control"
                id="componentTopic"
                name="componentTopic"
                placeholder="Enter the tags related to the component"
                value={componentTopic}
                onChange={(e) => setComponentTopic(e.target.value)}
                maxLength="40"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="componentDesc">
              Component Description{" "}
              <small className="text-light">(Max Length: 500 chars)</small>
            </label>
            <textarea
              className="form-control"
              id="componentDesc"
              name="componentDesc"
              placeholder="Enter a short component description"
              rows="4"
              value={componentDesc}
              onChange={(e) => setComponentDesc(e.target.value)}
              maxLength="500"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className={
              isLoading
                ? "btn btn-lg upload-btn disabled"
                : "btn btn-lg upload-btn"
            }
          >
            {isLoading ? "Saving.." : "Save Changes"}
          </button>
        </form>
      )}

      {editMsg && (
        <p className="lead text-center m-auto uploadMsg">{editMsg}</p>
      )}
    </div>
  );
};

export default ComponentEdit;
