import { useState, useEffect, Suspense } from "react";
import ComponentView from "../Common/ComponentView";
import LoadingSpinner from "../Util/LoadingSpinner";


const Latest = ({view, components, loadMore, limit, num, isLoading}) => {

    return (
        <div className="dashboard">
            {isLoading ? <LoadingSpinner className="m-auto" /> :
            <div className="row">
                { components && components.slice(0,limit).map((component)=>(
                        <ComponentView key={component.component_id} component={component} view={view}/>
                        // <ListItem key={component.component_id} component={component} />
                    ))
                }
            </div>
            }
            { (components.length >= limit) &&

            <div className="row">
                <button onClick={()=>{loadMore(num+1)}} className="btn btn-sm text-light m-auto"><strong>Load More <i className="fa fa-refresh"></i></strong></button>
            </div>

            }
        </div>
     );
}
 
export default Latest;