import Trending from "./Trending";
import NumToString from "../Util/NumToString";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Leaderboard = () => {

    const [topProfile,setTopProfile] =  useState(null);
    const [leadProfiles,setLeadProfiles] =  useState([]);

    useEffect(() => {
        refresh();
    },[])
    
    const refresh = () => {

        fetch('https://reuseportalbackendservice.cherrywork.com/leaderBoard')
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setTopProfile(data[0])
            setLeadProfiles(data.slice(1,5))
        })

    }

    return ( 
        <div className="leaderboard">
            <div className="card board">
                
                    <h3 className="card-title"><i className="fa fa-trophy text-warning"></i> &nbsp;Leaderboard<span><button className="btn refresh pt-0" onClick={refresh}><i className="fa fa-refresh"></i></button></span> </h3>
                    { topProfile &&
                        <div className="firstProfile">
                            <div className="row">
                                <div className="col-md-4 m-auto">
                                    <p className="h6">
                                        Rank
                                    </p>
                                    <p className="h4"><strong>1</strong></p>
                                </div>
                                <div className="col-md-4 m-auto">  
                                        <p><img src={"data:image/png;base64,"+topProfile.imageUrl} alt="profile" className=" firstPic rounded-circle" /></p>
                                </div>
                                <div className="col-md-4 m-auto ">
                                    <p className="h6">
                                        Points
                                    </p>
                                    <p className="h4"> <strong><NumToString points={topProfile.points}></NumToString></strong> </p>
                                </div>
                            </div>
                            <div className="row ">
                                <Link className="firstProfileName" to={"/home/profile/"+topProfile.email}>  
                                    <p className="text-center"><strong><i className="fa fa-diamond"></i>&nbsp;&nbsp;{topProfile.name}</strong></p>
                                </Link>
                            </div>
                        </div>
                    }
                
                    <ul className="list-group list-group-flush">
                    { leadProfiles &&  leadProfiles.map((profile)=>(
                        profile.points==="0"?"":
                        <Link key={profile.email} to={"/home/profile/"+profile.email}>
                        <li className="list-group-item">
                            <div className="row ">
                                <div className="col-md-2 m-auto">
                                    <img src={"data:image/png;base64,"+profile.imageUrl} alt="" className="secPic rounded-circle m-auto pl-0 pr-0" />
                                </div>
                                <div className="col-md-8 m-auto">
                                    <p className="otherProfileName m-auto">{profile.name}</p>
                                </div>
                                <div className="col-md-2 m-auto pl-0 pr-0">
                                    <NumToString points={profile.points}></NumToString>
                                </div>
                            </div>
                        </li>
                        </Link>
                        ))
                    }
                    </ul>
                
            </div>
            {/* <Trending/> */}
        </div>
     );
}
 
export default Leaderboard;