import { useEffect } from "react";
import { useState } from "react";

const ToggleSwitch = ({ label, role }) => {
  const [whichRole, setWhichRole] = useState(null);

  useEffect(() => {
    BtnShowRole();
  });

  function BtnShowRole() {
    if (role === "User") {
      setWhichRole(false);
    } else {
      setWhichRole(true);
    }
    console.log("role is: " + whichRole);
  }

  const handleChange = (e) => {
    e.preventDefault();

    let x = "?role=Admin";
    if (role === "Admin") {
      x = "?role=User";
    }

    fetch("https://reuseportalbackendservice.cherrywork.com/updateRole/" + label + x, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(),
    }).then(() => {
    });
  };

  return (
    <div className="container-toggle">
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          defaultChecked={whichRole}
          name={label}
          id={label}
          onChange={handleChange}
        />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
