import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Util/LoadingSpinner";

const Upload = ({initialLoad}) => {
  
  const navigate = useNavigate();
  const [uploadMsg, setUploadMsg] = useState(null);

  const [username, setUserName] = useState(sessionStorage.getItem("loggedInName"));
  const [userEmail, setUserEmail] = useState(sessionStorage.getItem("loggedInEmail"));
  const [componentName, setComponentName] = useState('');
  const [componentTopic, setComponentTopic] = useState('');
  const [componentDesc, setComponentDesc] = useState('');

  const [jarFile, setJarFile] = useState(null);
  const [sourceFile, setSourceFile] = useState(null);
  const [docFile, setDocFile] = useState(null);
  const today = new Date();
  const [todayDate, setTodayDate] = useState(today);

  // const checkFile= (e) => {
  //       let files = e.target.files;
  //       let reader = new FileReader();
  //       reader.readAsDataURL(files[0])

  //       reader.onload=(e)=>{
  //         console.log(e.target.result) 
  //       }
  // }

  const handleUploadFile = (e) => {
    e.preventDefault();
    var name = username;
    var email = userEmail;
    var component = componentName;
    var tag = componentTopic;
    var description = componentDesc;
    var jar = jarFile;
    var src = sourceFile;
    var document = docFile;
    var date = todayDate;



    // const formData = {name, email, component, tag, description, pom, jar, src, document};
    var url = '';
    if ((sourceFile === null || typeof sourceFile === 'undefined') && (jarFile === null || typeof jarFile === 'undefined')) {
      alert("Please upload either the Executable File or the Source File. You can upload both as well.");
      return;
    }

    //File size check
    if ((sourceFile != null && typeof sourceFile != 'undefined') && sourceFile.size >= 10000000) {
      alert("Source file size should not exceed 10 MB");
      return;
    }

    if ((docFile != null && typeof docFile != 'undefined') && docFile.size >= 10000000) {
      alert("Documentation file size should not exceed 10 MB");
      return;
    }

    if ((jarFile != null && typeof jarFile != 'undefined') && jarFile.size >= 10000000) {
      alert("Jar file size should not exceed 10 MB");
      return;
    }

    else if ((sourceFile === null || typeof sourceFile === 'undefined') && (jarFile !== null || typeof jarFile !== 'undefined')) {
      const fileName = jarFile.name;
      
      const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (extension === 'docx' || extension === 'pdf' || extension === 'rar' || extension === 'tar' || extension === 'zip') {
        alert("Please upload a valid Executable File.");
        return;
      } else {
        url = "https://reuseportalbackendservice.cherrywork.com/uploadWithOutSrc/";
      }
    }
    else if ((jarFile === null || typeof jarFile === 'undefined') && (sourceFile !== null || typeof sourceFile !== 'undefined')) {
      url = "https://reuseportalbackendservice.cherrywork.com/uploadWithOutJar/";
    }
    else {
      const fileName = jarFile.name;
      const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (extension === 'docx' || extension === 'pdf' || extension === 'rar' || extension === 'tar' || extension === 'zip') {
        alert("Please upload a valid Executable File.");
        return;
      } else {
        url = "https://reuseportalbackendservice.cherrywork.com/upload/";
      }
    }



    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("component", component);
    formData.append("tag", tag);
    formData.append("description", description);
    formData.append("jar", jar);
    formData.append("src", src);
    formData.append("document", document);
    formData.append("date", date);


    setUploadMsg("Uploading Files...")

    if (url !== '') {
      fetch(url, {

        method: 'POST',
        body: formData

      }).then((res) => {
        return res.json();
      }).then((data) => {
        if (data.status === '1') {
          initialLoad();
          navigate('/home/mycomponents');
        }
        else {
          setUploadMsg("Uploading Failed");
        }
      })
    }

  }


  return (
    <div className="uploadArtefact">
      <p className="h2 headtxt">Upload a Component &nbsp;<i className="fa fa-upload"></i></p>
      {!uploadMsg && <form id="uploadFile" encType='multipart/form-data' onSubmit={handleUploadFile}>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="username">Name</label>
            <input
              type="text"
              className="form-control"
              name="username"
              id="username"
              placeholder="Enter your name"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              required
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="userEmail">Email</label>
            <input
              type="text"
              className="form-control"
              id="userEmail"
              name="userEmail"
              placeholder="Enter your email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="componentName">Component Name <small className="text-light">(Max Length: 40 chars)</small></label>
            <input
              type="text"
              className="form-control"
              name="componentName"
              id="componentName"
              placeholder="Enter the component name"
              value={componentName}
              onChange={(e) => setComponentName(e.target.value)}
              maxLength="40"
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="componentTopic">Tags <small className="text-light">(separated by comma) (Max Length: 40 chars)</small> </label>
            <input
              type="text"
              className="form-control"
              id="componentTopic"
              name="componentTopic"
              placeholder="Enter the tags related to the component"
              value={componentTopic}
              onChange={(e) => setComponentTopic(e.target.value)}
              maxLength="40"
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="componentDesc">Component Description <small className="text-light">(Max Length: 500 chars)</small></label>
          <textarea
            className="form-control"
            id="componentDesc"
            name="componentDesc"
            placeholder="Enter a short description for the component"
            rows="4"
            value={componentDesc}
            onChange={(e) => setComponentDesc(e.target.value)}
            maxLength="500"
            required>

          </textarea>
        </div>
        <div className="form-group files jarFile">
          <label htmlFor="jarFile">Upload the Executable File &nbsp;<abbr title="only executable files allowed"><i className="fa fa-info-circle"></i></abbr></label>
          <input
            type="file"
            id="jarFile"
            className="form-control"
            name="jarFile"
            onChange={(e) => setJarFile(e.target.files[0])}
          />
        </div>
        <div className="form-group files">
          <label htmlFor="sourceFile">Upload the Source Code &nbsp;<abbr title="only .zip and .tar files allowed"><i className="fa fa-info-circle"></i></abbr>  </label>
          <input
            type="file"
            id="sourceFile"
            name="sourceFile"
            className="form-control"
            onChange={(e) => setSourceFile(e.target.files[0])}
          />
        </div>
        <div className="form-group files">
          <label htmlFor="docFile">Upload the Documentation File  &nbsp;<abbr title="only text files allowed"><i className="fa fa-info-circle"></i></abbr>  </label>
          <input
            type="file"
            id="docFile"
            className="form-control"
            onChange={(e) => setDocFile(e.target.files[0])}
            required
          />
        </div>
        <button type="submit" className="btn btn-lg upload-btn">Upload</button>
      </form>}

      {
        uploadMsg &&
        <>
                <div id="wait-loader" className="wait-loader align-items-center justify-content-center" >
                    <LoadingSpinner/>
                </div>
          <p className="h1 text-center m-auto uploadMsg">
            {uploadMsg}
            <br /><br />
            <p className="lead text-center m-auto ">
              Please wait. You will be redirected to a page...
            </p>
          </p>
          
        </>
      }


    </div>
  );
}

export default Upload;