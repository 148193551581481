import { useState, useEffect } from "react";
import Announcement from "./Announcement";
import { Link, Route, Router, Routes } from "react-router-dom";
import Mail from "./Mail";
import PointTableManipulation from "./PointTableManipulation";
import RoleAssign from "./RoleAssign";

const Admin = () => {
  const [adminAccess, setAdminAccess] = useState(false);

  //Toggle Page
  const [isAnnouncement, setIsAnnouncement] = useState(true);
  const [isRoleAssign, setIsRoleAssign] = useState(false);
  const [isPointTablemanipulation, setIsPointTableManipulation] =
    useState(false);

  const toggleButton = (page) => {
    if (page === "Announcement") {
      setIsAnnouncement(true);
      setIsRoleAssign(false);
      setIsPointTableManipulation(false);
    } 
     else if(page === "Role Assign"){
      setIsAnnouncement(false);
      setIsRoleAssign(true);
      setIsPointTableManipulation(false);

    } 
     else {
      setIsAnnouncement(false);
      setIsRoleAssign(false);
      setIsPointTableManipulation(true);
    }
  };

  useEffect(() => {
    adminCheck();
  }, [adminAccess]);
  function adminCheck() {
    //sessionStorage.getItem("loggedInEmail")
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/adminCheck/email/bishwa.das@incture.com"
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAdminAccess(data);
        
      });
  }

 


  return (
    <div className="admin-page">
      <p className="h3 headtxt">Admin Panel</p>
      <div className="admin-body">
        {adminAccess && (
          <>
            <div className="btnGroup" role="group">
              <Link to="/home/admin/announcement">
                <button
                  onClick={() => toggleButton("Announcement")}
                  type="button"
                  className={
                    "btn headBtn " + (isAnnouncement ? "active-option" : "")
                  }
                >
                  <i className="fa fa-bullhorn"></i>&nbsp; Announcement
                </button>
              </Link>
              {/* <Link to="/home/admin/notificationSetting">
                <button
                  onClick={() => toggleButton("Mail")}
                  type="button"
                  className={"btn headBtn " + (isMail ? "active-option" : "")}
                >
                  <i className="fa fa-bell"></i>&nbsp; Notification Setting
                </button>
              </Link> */}
              <Link to="/home/admin/roleAssign">
                <button
                  onClick={() => toggleButton("Role Assign")}
                  type="button"
                  className={"btn headBtn " + (isRoleAssign ? "active-option" : "")}
                >
                  <i className="fa fa-user"></i>&nbsp; Assign Role
                </button>
              </Link>
              {/* <Link to="/home/admin/pointTableManipulation">
                <button
                  onClick={() => toggleButton("Point Table Manipulation")}
                  type="button"
                  className={
                    "btn headBtn " +
                    (isPointTablemanipulation ? "active-option" : "")
                  }
                >
                  {" "}
                  <i className="fa fa-trophy"></i>&nbsp; Point Table
                  Manipulation
                </button>
              </Link> */}
            </div>
            <div className="container" id="cards">
            <Routes>
                <Route path="/" element={<Announcement />} />
              </Routes>
              <Routes>
                <Route path="/announcement" element={<Announcement />} />
              </Routes>
              {/* <Routes>
                <Route path="/notificationSetting" element={<Mail />} />
              </Routes> */}
              <Routes>
                <Route path="/roleAssign/*" element={<RoleAssign />} />
              </Routes>
              {/* <Routes>
                <Route
                  path="/pointTableManipulation"
                  element={<PointTableManipulation />}
                />
              </Routes> */}
            </div>
            
          </>
        )}
        {!adminAccess && (
          <p className="h6">
            Unauthorized access. Please contact the admin for details.
          </p>
        )}
      </div>
    </div>
  );
};

export default Admin;
