import Dashboard from "./Dashboard/Dashboard";
import { Link, Route, Routes } from "react-router-dom";
import Upload from "./Upload/Upload";
import MyComponents from "./MyComponents/MyComponents";
import Settings from "./Settings/Settings";
import Search from "./Search/Search";
import SideProfile from "./Common/SideProfile";
import Leaderboard from "./Common/Leaderboard";
import Profile from "./Profile/Profile";
import SingleComponent from "./SingleComponent/SingleComponent";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ComponentEdit from "./SingleComponent/ComponentEdit";
import ComponentDelete from "./SingleComponent/ComponentDelete";
import ComponentChart from "./SingleComponent/ComponentChart";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import logo_reuse from "./logo_reuse.png";
import Admin from "./Admin/Admin";
import AnnouncementPanel from "./Common/AnnouncementPanel";
import Analytics from "./Analytics/Analytics";


const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {

    if (sessionStorage.getItem("loggedInEmail") == null) {
      navigate('/');
    }
    initialLoad();
  }, []);
  const [searchQuery, setSearchQuery] = useState(null);

  const { instance, accounts } = useMsal();
  function handleLogout(instance) {
    instance
      .logoutPopup()
      .then(() => {
        sessionStorage.clear();
        navigate("/");
      })
      .catch((e) => {
        console.log(e);
      });
  }
  const [isLoading, setIsLoading] = useState(false);

  //Latest Components
  const [latestComponents, setLatestComponents] = useState([]);

  //Most Downloaded Components
  const [mostDownloadedComponents, setMostDownloadedComponents] = useState([]);

  //Most Liked Components
  const [mostLikedComponents, setMostLikedComponents] = useState([]);

  const initialLoad = () => {
    setIsLoading(true);
    //Fetch first set of latest components
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/cardDetails/component/latestlist/id/1"
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setLatestComponents(data);
        setIsLoading(false);
      });

    //Fetch first set of most downloaded components
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/cardDetails/component/downloadedlist/id/1"
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setMostDownloadedComponents(data);
      });

    //Fetch first set of most liked components
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/cardDetails/component/likedlist/id/1"
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setMostLikedComponents(data);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    var searchQ = document.getElementById("search-bar").value;
    setSearchQuery(searchQ);
    navigate("/home/search");
  };

  const downloadReadMe = () => {
    window.open("https://reuseportal.cherrywork.com/ReadMe.pdf", "_blank");
  };

  const handleNotificationaPanel = () => {
    document.getElementById("myDropdown").classList.toggle("show");

    // Close the dropdown if the user clicks outside of it
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
  };

  return (
    <div className="sideBar">
      <div id="header">
        <nav className="fixed-top navbar-light bg-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1 d-flex align-items-center justify-content-left">
                <Link to="/home" className="navbar-brand">
                  <img src={logo_reuse} alt="" />
                </Link>
              </div>
              <div className="col-md-10 d-flex align-items-center justify-content-center px-1">
                <div className="row-ann">
                  <AnnouncementPanel />
                </div>
              </div>
              <div className="col-md-1 d-flex align-items-center justify-content-right px-0">
                <form className="announcement-forum form-inline">
                <button
                  onClick={downloadReadMe}
                  className="btn "
                  title="ReadMe"
                  type="button"
                  id="notification-btn"
                >
                  <i className="fa fa-book m-auto"></i>
                </button>
                <div className="dropdown drplft">
                  <button
                    className="btn dropbtn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    type="button"
                    id="notification-btn"
                  >
                    {sessionStorage.getItem("loggedInName")[0] +
                      sessionStorage
                        .getItem("loggedInName")
                        .split(" ")
                        .slice(
                          sessionStorage.getItem("loggedInName").split(" ")
                            .length - 1
                        )[0][0]}
                  </button>
                  <div className="dropdown-menu dropdown-menu-right text-center">
                    <h1 className="dropdown-header">
                      {sessionStorage.getItem("loggedInName")}
                    </h1>
                    <h1 className="dropdown-subheader">
                      {sessionStorage.getItem("loggedInEmail")}
                    </h1>
                    <div className="d-flex justify-content-center">
                      <button
                        className="dropdown-item"
                        onClick={() => handleLogout(instance)}
                        type="button"
                      >
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                        &nbsp;&nbsp;<small>Sign Out</small>
                      </button>
                    </div>
                  </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <section id="main">
        <div className="row" id="main-row">
          <div className="col-md-2 m-0 p-0">
            <SideProfile />
          </div>

          <div className="col-md-7 " id="content">
            <div className="card" id="content-card">
              <div className="card-body p-1 pt-0 text-left">
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Dashboard
                        isLoading={isLoading}
                        latestComponents={latestComponents}
                        setLatestComponents={setLatestComponents}
                        mostDownloadedComponents={mostDownloadedComponents}
                        setMostDownloadedComponents={
                          setMostDownloadedComponents
                        }
                        mostLikedComponents={mostLikedComponents}
                        setMostLikedComponents={setMostLikedComponents}
                        handleSearch={handleSearch}
                      />
                    }
                  />

                  <Route
                    path="/dashboard/*"
                    element={
                      <Dashboard
                        isLoading={isLoading}
                        latestComponents={latestComponents}
                        setLatestComponents={setLatestComponents}
                        mostDownloadedComponents={mostDownloadedComponents}
                        setMostDownloadedComponents={
                          setMostDownloadedComponents
                        }
                        mostLikedComponents={mostLikedComponents}
                        setMostLikedComponents={setMostLikedComponents}
                        handleSearch={handleSearch}
                      />
                    }
                  />

                  <Route
                    path="/upload"
                    element={<Upload initialLoad={initialLoad} />}
                  />

                  <Route path="/profile/:email" element={<Profile />} />

                  <Route path="/mycomponents/*" element={<MyComponents />} />

                  <Route path="/settings/*" element={<Settings />} />

                  <Route path="/admin/*" element={<Admin />}>
                    {/* alert("Clicked"); */}

                    {/* <Route path='announcement' element={<Annoncement/>}/> */}
                  </Route>
                  <Route path="/analytics/*" element={<Analytics />} />

                  <Route path="/component/:id" element={<SingleComponent />} />

                  <Route path="/edit/:id" element={<ComponentEdit />} />

                  <Route
                    path="/delete/:id"
                    element={<ComponentDelete initialLoad={initialLoad} />}
                  />

                  <Route path="/chart/:id" element={<ComponentChart />} />

                  <Route
                    path="/search"
                    element={
                      <Search
                        searchQuery={searchQuery}
                        handleSearch={handleSearch}
                      />
                    }
                  />

                  {/* <Route path="/admin/announcement" element={<Annoncement/>}/> */}

                  {/* <Route path="/admin/mail" element={<Mail/>}/> */}

                  {/* <Route path="/admin/pointtablemanipulation" element={<PointTableManipulation/>}/>  */}
                </Routes>
              </div>
            </div>
          </div>
          <div className="col-md-3 m-0 p-0">
            <Leaderboard />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
