import './App.css';
import Home from './Home';
import { BrowserRouter as Router,  Route, Routes } from 'react-router-dom';
import Login from './Login/Login';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/home/*" element={<Home/>}/>
            
        </Routes>
        {/* <Home/> */}
      </Router>
      
    </div>
  );
}

export default App;
