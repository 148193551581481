import { useState, useEffect } from "react";

const Announcement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [announcement_body, set_announcement_text] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("https://reuseportalbackendservice.cherrywork.com/addAnnouncement", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ announcement_body }),
    }).then(() => {
    });


      document.getElementById("postBtn").textContent="Posted";

    

  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>Write the announcement to be posted</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter new announcement"
              value={announcement_body}
              onChange={(e) => set_announcement_text(e.target.value)}
              maxLength='150'
              required
            />
          </div>
        </div>

        <button
          type="submit"
          id="postBtn"
          className={
            isLoading
              ? "btn btn-sm upload-btn disabled "
              : "btn btn-sm upload-btn btn-ann"
          }
        >
          {isLoading ? "Posting.." : "Post"}
        </button>
      </form>
    </div>
  );
};

export default Announcement;
