import { useState, useEffect } from "react";
import { Link, Route, Routes } from 'react-router-dom';
import NotificationSettings from './NotificationSettings';
import ProfileSettings from './ProfileSettings';
import LoadingSpinner from "../Util/LoadingSpinner";

const Settings = () => {
    
    const [settingsProfile, setSettingsProfile] = useState(null)
    const [isProfile, setIsProfile] = useState(true)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        fetch('https://reuseportalbackendservice.cherrywork.com/userDetails/uid/'+ sessionStorage.getItem("loggedInId"))
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            console.log(data);
            setSettingsProfile(data);
            setIsLoading(false);
        })

    },[])

    const toggleButton = (page) => {
        if (page==="profile") {
            setIsProfile(true)
        }
        else{
            setIsProfile(false)
        }
    }

    return(
            <div className="settings">
                <p className="h2 headtxt">Profile Settings</p>
                {/* <div className="btnGroup" role="group">
                    <Link to="/home/settings/profile"><button onClick={() => toggleButton("profile")} type="button" className={"btn headBtn " + (isProfile?"active-option":"")}>Profile</button></Link>
                    <Link to="/home/settings/notifications"><button onClick={() => toggleButton("notif")} type="button" className={"btn headBtn " + (!isProfile?"active-option":"")}>Notifications</button></Link>
                </div> */}
                {   isLoading ? <LoadingSpinner className="m-auto"/> :
                    ( settingsProfile &&
                        <div className="settingSpace">
                            <Routes>
                                    <Route path="/" element={<ProfileSettings settingsProfile={settingsProfile} />}/>
                            </Routes>
                            <Routes>
                                    <Route path="/profile" element={<ProfileSettings settingsProfile={settingsProfile}/>}/>
                            </Routes>
                            <Routes>
                                <Route path="/notifications" element={<NotificationSettings/>}/>
                            </Routes>  
                        </div>
                    )
                }       
            </div>
    );
}
export default Settings;