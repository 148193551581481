import Card from "../Common/Card";
import ListItem from "../Common/ListItem";

const ComponentView = ({view,component}) => {
    if (view === "grid") {
        return(
            <Card component={component} />
        );
    } else {
        return(
            <ListItem component={component} />
        );
    }
}

export default ComponentView;