import { useState, useEffect, Suspense } from "react";
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import Latest from "./Latest";
import MostDownloaded from "./MostDownloaded";
import MostLiked from "./MostLiked";

const Dashboard = ({ isLoading, latestComponents, setLatestComponents,mostDownloadedComponents,setMostDownloadedComponents, mostLikedComponents,  setMostLikedComponents, handleSearch}) => {
    //Loading Data
    // const [isLoading, setIsLoading] = useState(false);

    const [num, setNum] = useState({latest:1, downloaded:1, liked:1});
    const [limit, setLimit] = useState({latest:8, downloaded:8, liked:8});

    //Toggle Page
    const [isLatest, setIsLatest] = useState(true)
    const [isDownload, setIsDownload] = useState(false)
    const [isLiked, setIsLiked] = useState(false)
    
    //Toggle Component View
    const [isList, setIsList] = useState(false)
    const [isGrid, setIsGrid] = useState(true)
    const [view, setView] = useState("grid")

    
    const loadMore = (newNum) => {
        var url = ""
        if (isLatest) {
            setNum({...num, latest:newNum})
            url = 'https://reuseportalbackendservice.cherrywork.com/cardDetails/component/latestlist/id/';
        }
        else if(isDownload) {
            setNum({...num, downloaded:newNum})
            url = 'https://reuseportalbackendservice.cherrywork.com/cardDetails/component/downloadedlist/id/';
        }
        else {
            setNum({...num, liked:newNum})
            url = 'https://reuseportalbackendservice.cherrywork.com/cardDetails/component/likedlist/id/';
        }
        fetch(url+newNum)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{

            var resultData = []
            if (isLatest) {
                resultData = [...latestComponents, ...data];
                setLatestComponents(resultData);
                setLimit({...limit, latest:limit.latest+8});
            }
            else if(isDownload) {
                resultData = [...mostDownloadedComponents, ...data];
                setMostDownloadedComponents(resultData);
                setLimit({...limit, downloaded:limit.downloaded+8});
            }
            else {
                resultData = [...mostLikedComponents, ...data];
                setMostLikedComponents(resultData);
                setLimit({...limit, liked:limit.liked+8});
            }
        })
    }

    const toggleButton = (page) => {
        if (page==="latest") {
            setIsLatest(true)
            setIsDownload(false)
            setIsLiked(false)
        }
        else if(page==="download"){
            setIsLatest(false)
            setIsDownload(true)
            setIsLiked(false)
        }
        else{
            setIsLatest(false)
            setIsDownload(false)
            setIsLiked(true)
        }
    }

    const toggleView = (view) => {
        if (view==="list") {
            setIsList(true)
            setIsGrid(false)            
            setView("list")
        }
        else if(view==="grid"){
            setIsList(false)
            setIsGrid(true)
            setView("grid")
        }
    }


    
    return(
        
                        <div className="dashboard">
                                <p className="h3 headtxt">Dashboard</p>
                                <form className="search-form form-inline" onSubmit={handleSearch}>
                                    <div className="input-group">
                                    <input id="search-bar" type="search" className="form-control text-muted" placeholder="Search for Reusable Component" />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary d-flex align-items-center justify-content-center" id="search-btn" type="submit">
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                    </div>
                                </form>
                                        <div className="btnGroup" role="group">
                                            <Link to="/home/dashboard/latest"><button onClick={() => toggleButton("latest")} type="button" className={"btn headBtn "+(isLatest?"active-option":"")}><i className="fa fa-star"></i>&nbsp; Latest</button></Link>
                                            <Link to="/home/dashboard/downloaded"><button onClick={() => toggleButton("download")} type="button" className={"btn headBtn "+(isDownload?"active-option":"")}><i className="fa fa-download"></i>&nbsp; Most Downloaded</button></Link>
                                            <Link to="/home/dashboard/liked"><button onClick={() => toggleButton("liked")} type="button" className={"btn headBtn "+(isLiked?"active-option":"")}><i className="fa fa-thumbs-up"></i>&nbsp; Most Liked</button></Link>
                                            <div className="btn-group float-right" role="group">
                                                <button onClick={() => toggleView("grid")} type="button" className={"btn headBtn "+(isGrid?"active-option":"")}><i className="fa fa-th-large"></i></button>
                                                <button onClick={() => toggleView("list")} type="button" className={"btn headBtn "+(isList?"active-option":"")}><i className="fa fa-list-ul"></i></button>
                                            </div>
                                        </div>
                                        <div className="container" id="cards">

                                        <Routes>
                                                <Route path="/" element={<Latest isLoading={isLoading} view={view} components={latestComponents} setComponents={setLatestComponents} loadMore={loadMore} limit={limit.latest} num={num.latest} />}/>
                                        </Routes>
                                        <Routes>
                                                <Route path="/latest" element={<Latest isLoading={isLoading} view={view} components={latestComponents} setComponents={setLatestComponents} loadMore={loadMore} limit={limit.latest} num={num.latest}/>}/>
                                        </Routes>
                                        <Routes>
                                            <Route path="/downloaded" element={<MostDownloaded isLoading={isLoading} view={view} components={mostDownloadedComponents} setComponents={setMostDownloadedComponents} loadMore={loadMore} limit={limit.downloaded} num={num.downloaded}/>}/>
                                        </Routes>
                                        <Routes>
                                            <Route path="/liked" element={<MostLiked isLoading={isLoading} view={view} components={mostLikedComponents} setComponents={setMostLikedComponents} loadMore={loadMore} limit={limit.liked} num={num.liked}/>}/>
                                        </Routes>
                                        </div>

                        </div>
    );
}

export default Dashboard;