import Card from "../Common/Card";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const MyUploads = () => {
    const [uploadFiles,setUploadFiles] =  useState([]);
    const [isUploadFiles,setIsUploadFiles] =  useState(true);

    useEffect(() => {

        fetch('https://reuseportalbackendservice.cherrywork.com/cardDetails/component/email/'+sessionStorage.getItem("loggedInEmail"))
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setUploadFiles(data.reverse());
            if (data.length===0) {
                setIsUploadFiles(true);
            }
            else{
                setIsUploadFiles(false);
            }
            
        })

    },[])

    return (
        <div className="row">
            { uploadFiles && uploadFiles.map((component)=>(
                        <Card key={component.component_id} component={component} />
                ))
            }
            { isUploadFiles && <p className="error-message">No Components Uploaded Yet.</p> } 
        </div>
        
     );
}
 
export default MyUploads;