import ToggleSwitch from "./ToggleSwitch";

const SearchName = ({ searchFiles }) => {

  return (
    <div className="row">
      <div>
        <div className="someee">
          {searchFiles.map((sf) => (
            <div className="row">
              <div className="yo col-md-10">
                {sf.name} | {sf.position}
              </div>
              <div className="col-md-2">
                <ToggleSwitch label={sf.uid} role={sf.role} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchName;
