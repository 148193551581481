import { useState, useEffect } from "react";

const NumToString = ({points}) => {
    const [stringPoints, setStringPoints] = useState(points);
    useEffect(() => {
        intToString(points)
    },[points])

    const intToString= (stringPoints) => {
        stringPoints = stringPoints.toString().replace(/[^0-9.]/g, '');
        if (stringPoints < 1000) {
            setStringPoints(stringPoints);
        }
        else{
            let si = [
                {v: 1E3, s: "K"},
                {v: 1E6, s: "M"},
                {v: 1E9, s: "B"},
                {v: 1E12, s: "T"},
                {v: 1E15, s: "P"},
                {v: 1E18, s: "E"}
                ];
              let index;
              for (index = si.length - 1; index > 0; index--) {
                  if (stringPoints >= si[index].v) {
                      break;
                  }
              }
              setStringPoints((stringPoints / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s);
        }
    }
    
    return (
        stringPoints
    );
};

export default NumToString;
