import Card from "../Common/Card";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../Util/LoadingSpinner";

const Search = ({ searchQuery, handleSearch }) => {
  //

  const [resultCount, setResultCount] = useState(0);
  const [searchFiles, setSearchFiles] = useState([]);
  const [isSearchFiles, setIsSearchFiles] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [num, setNum] = useState(1);
  const [limit, setLimit] = useState(8);

  useEffect(() => {
    setIsLoading(true);
    setLimit(8);
    setNum(1);
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/cardDetails/component/search/" +
        searchQuery +
        "/num/" +
        "1"

      
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setResultCount(data.length);
        setSearchFiles(data);
        setIsSearchFiles(false);
        setIsLoading(false);
      });
  }, [searchQuery]);

  const loadGridMore = (newNum) => {
    setNum(newNum);
    fetch(
      "https://reuseportalbackendservice.cherrywork.com/cardDetails/component/search/" +
        searchQuery +
        "/num/" +
        newNum

     
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const resultData = [...searchFiles, ...data];
        setResultCount(resultData.length);
        setSearchFiles(resultData);
        setLimit(limit + 8);
      });
  };

  return (
    <div className="searchFiles">
      <p className="h2 headtxt">Results</p>
      <form className="search-form form-inline" onSubmit={handleSearch}>
          <div className="input-group">
            <input id="search-bar" type="search" className="form-control text-muted" placeholder="Search for Reusable Component" />
            <div className="input-group-append">
              <button className="btn btn-secondary d-flex align-items-center justify-content-center" id="search-btn" type="submit">
                  <i className="fa fa-search"></i>
              </button>
            </div>
        </div>
      </form>
      <p className="search-query">
        Displaying {resultCount} results for "{searchQuery}"
      </p>
      {isLoading ? (
        <LoadingSpinner className="m-auto" />
      ) : (
        <div className="container" id="cards">
          <div className="row">
            {searchFiles &&
              searchFiles
                .slice(0, limit)
                .map((component) => (
                  <Card key={component.component_id} component={component} />
                ))}
            {isSearchFiles && (
              <p className="error-message">No searchFiles Found.</p>
            )}
          </div>
        </div>
      )}
      {searchFiles.length >= limit && (
        <div className="row">
          <button
            onClick={() => {
              loadGridMore(num + 1);
            }}
            className="btn btn-sm text-light m-auto"
          >
            <strong>
              Load More <i className="fa fa-refresh"></i>
            </strong>
          </button>
        </div>
      )}
    </div>
  );
};

export default Search;
