import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import RoleAssignSearch from "../Search/RoleAssignSearch";
import { Link, Route, Router, Routes } from "react-router-dom";


const RoleAssign = () => {

  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState(null);

  const handleSearch = (e) => {
    e.preventDefault();
    var searchQ = document.getElementById("search-bar-ra").value;
    setSearchQuery(searchQ);
    navigate("/home/admin/roleAssign/roleAssignSearch");
  };

 
  return (
    <div>
      <form className="search-form form-inline" onSubmit={handleSearch}>
        <input
          id="search-bar-ra"
          className="form-control text-muted col-md-10"
          type="search"
          placeholder="Search User"
          required
        />
        <button id="role-search-btn" className="btn" type="submit">
          <i className="fa fa-search"></i>
        </button>
      </form>


      <div>
        <Routes>
          <Route path="/roleAssignSearch" element={<RoleAssignSearch searchQuery={searchQuery}/>} />
        </Routes>
      </div>
      
    </div>
  );
};

export default RoleAssign;
