import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

const SideProfile = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  function handleLogout(instance) {
    instance
      .logoutPopup()
      .then(() => {
        sessionStorage.clear();
        navigate("/");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <div className="sideProfile">
      <div className="card">
        <img
          className="card-img-top m-auto"
          src={
            "data:image/png;base64," + sessionStorage.getItem("loggedInImage")
          }
          alt="Card cap"
        />
        <div className="card-body text-center">
          {sessionStorage.getItem("loggedInPosition") && (
            <Link
              to={"/home/profile/" + sessionStorage.getItem("loggedInEmail")}
            >
              <p className="h5">
                Hello, {sessionStorage.getItem("loggedInName").split(" ")[0]}!
              </p>
            </Link>
          )}
        </div>
        <ul className="list-group list-group-flush text-left">
          <Link to="/home/">
            <li className="list-group-item">
              <div className="container">
                <div className="row">
                  <div className="profile-logo col-md-2 d-flex align-items-center justify-content-right">
                    <i className="fa fa-tasks m-auto"></i>
                  </div>
                  <div className="col-md-9 d-flex align-items-center justify-content-left">
                    <span className="profile-options">Dashboard</span>
                  </div>
                </div>
              </div>
            </li>
          </Link>
          <Link to="/home/mycomponents">
            <li className="list-group-item">
              <div className="container">
                <div className="row">
                  <div className="profile-logo col-md-2 d-flex align-items-center justify-content-right">
                  <i className="fa fa-cloud"></i>
                  </div>
                  <div className="col-md-9 d-flex align-items-center justify-content-left">
                    <span className="profile-options">My Components</span>
                  </div>
                </div>
              </div>
            </li>
          </Link>
          <Link to="/home/upload">
            <li className="list-group-item">
              <div className="container">
                <div className="row">
                  <div className="profile-logo col-md-2 d-flex align-items-center justify-content-right">
                    <i className="fa fa-upload"></i>
                  </div>
                  <div className="col-md-9 d-flex align-items-center justify-content-left">
                    <span className="profile-options">Upload</span>
                  </div>
                </div>
              </div>
            </li>
          </Link>
          <Link to="/home/analytics">
            <li className="list-group-item">
                <div className="container">
                  <div className="row">
                    <div className="profile-logo col-md-2 d-flex align-items-center justify-content-right">
                      <i className="fa fa-line-chart"></i>
                    </div>
                    <div className="col-md-9 d-flex align-items-center justify-content-left">
                      <span className="profile-options">Analytics</span>
                    </div>
                  </div>
                </div>
            </li>
          </Link>
          <Link to="/home/settings">
            <li className="list-group-item">
                <div className="container">
                  <div className="row">
                    <div className="profile-logo col-md-2 d-flex align-items-center justify-content-right">
                      <i className="fa fa-cog"></i>
                    </div>
                    <div className="col-md-9 d-flex align-items-center justify-content-left">
                      <span className="profile-options">Profile Settings</span>
                    </div>
                  </div>
                </div>
            </li>
          </Link>

          {sessionStorage.getItem("loggedInRole") === "Admin" && (
            <Link to="/home/admin">
              <li className="list-group-item">
                <div className="container">
                  <div className="row">
                    <div className="profile-logo col-md-2 d-flex align-items-center justify-content-right">
                      <i className="fa fa-lock"></i>
                    </div>
                    <div className="col-md-9 d-flex align-items-center justify-content-left">
                      <span className="profile-options">Admin Panel</span>
                    </div>
                  </div>
                </div>
              </li>
            </Link>
          )}
        </ul>
        {/* <div className="card-body text-center">
                                <button onClick={() => handleLogout(instance)} className="signout-btn btn btn-lg">Signout</button>
                                </div> */}
      </div>
    </div>
  );
};

export default SideProfile;
