import { useState,useEffect } from "react";


const Applicationanalytics = () => {

    const [totalUsers, setTotalUsers] = useState(null)
    const [totalPosts, setTotalPosts] = useState(null)
    const [activeuser, setActiveuser] = useState(null)

    useEffect(() => {
    
        fetch('https://reuseportalbackendservice.cherrywork.com/usersCount')
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            //console.log(data);
            setTotalUsers(data.numofusers)
        })
        fetch('https://reuseportalbackendservice.cherrywork.com/filesCount')
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            //console.log(data);
            setTotalPosts(data.numoffiles)
        })
        fetch('https://reuseportalbackendservice.cherrywork.com/CountTotalUserWhoPosted')
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            //console.log(data);
            setActiveuser(data.CountTotalUsersWhoPosted)
        })
    },[])
    console.log(activeuser)
    return ( 
    <div className="quality">
        <div className="row">
            <div className="col-md-4">
                <div className="box">
                    <i className="fa fa-users fa-3x" aria-hidden="true"></i>
					<div className="box-title">
						Total number of users who have accessed the application
					</div>
					<div className="box-text">
                        {totalUsers}
				    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="box">
                    <i className="fa fa-user-plus fa-3x" aria-hidden="true"></i>
					<div className="box-title">
						Total number of users who have uploaded a reusable component
					</div>
					<div className="box-text">
                        {activeuser}
				    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="box">
                    <i className="fa fa-file fa-3x" aria-hidden="true"></i>
					<div className="box-title">
						Total number of reusable components uploaded to the application
					</div>
					<div className="box-text">
                        {totalPosts}
				    </div>
                </div>
            </div>
        </div>
                
    </div> );
}
 
export default Applicationanalytics;