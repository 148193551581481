import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ProfileSettings = ({ settingsProfile }) => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [alternateEmail, setAlternateEmail] = useState(settingsProfile.alternate_email ? settingsProfile.alternate_email : "");
    const [UBio, setUBio] = useState(settingsProfile.bio ? settingsProfile.bio : "");
    const [UEmail, setUEmail] = useState(settingsProfile.email);
    const [fieldOfExpertise, setFieldOfExpertise] = useState(settingsProfile.field_of_expertise ? settingsProfile.field_of_expertise : "");
    const [githubUrl, setGithubUrl] = useState(settingsProfile.github_url ? settingsProfile.github_url : "");
    const [linkedinUrl, setLinkedinUrl] = useState(settingsProfile.linkedin_url ? settingsProfile.linkedin_url : "");
    const [UName, setUName] = useState(settingsProfile.name);
    const [phoneNum, setPhoneNum] = useState(settingsProfile.phone_num ? settingsProfile.phone_num : "");
    const [UPosition, setPosition] = useState(settingsProfile.position);
    const [profilePic, setProfilePic] = useState(null);

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        setIsLoading(true);
        var url = '';
        if (profilePic === null) {
            url = "https://reuseportalbackendservice.cherrywork.com/updateDetailsWithoutImage";
        } else {
            url = "https://reuseportalbackendservice.cherrywork.com/updateDetailsWithImage";
        }

        const formData = new FormData();
        formData.append("uid", sessionStorage.getItem("loggedInId"));
        formData.append("email", sessionStorage.getItem("loggedInEmail"));
        formData.append("name", sessionStorage.getItem("loggedInName"));
        formData.append("position", sessionStorage.getItem("loggedInPosition"));
        formData.append("altemail", alternateEmail);
        formData.append("linkedin", linkedinUrl);
        formData.append("image", profilePic);
        formData.append("github", githubUrl);
        formData.append("phno", phoneNum);
        formData.append("bio", UBio);
        formData.append("foe", fieldOfExpertise);

        fetch(url, {

            method: 'PUT',
            body: formData

        }).then((res) => {
            return res.json();
        }).then((data) => {
            setIsLoading(false);
            navigate('/home/profile/' + UEmail);

        })
    }

    const handleChange = (e) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === '' || regex.test(e.target.value)) {
            setPhoneNum(e.target.value)
        }
    }

    return (
        <   div className="profile-settings">
            {settingsProfile &&
                <form onSubmit={handleUpdateProfile}>
                    <div className="form-row settings-profile settings-user-details">
                        <div className="form-group col-md-3 settings-user-img">
                            <img
                                src={"data:image/png;base64," + settingsProfile.imageUrl}
                                alt=""
                                className="img-fluid rounded-circle settings-img"
                            />
                            <input
                                type="file"
                                id="profilePic"
                                className="form-control"
                                name="profilePic"
                                onChange={(e) => setProfilePic(e.target.files[0])}
                            />
                        </div>
                        <div className="form-group col-md-9">
                            <div className="form-row">
                                <label htmlFor="fullName">Full Name</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="fullName"
                                    placeholder="Your full name"
                                    value={UName}
                                    onChange={(e) => setUName(e.target.value)}
                                    disabled
                                />
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6 pl-0">
                                    <label htmlFor="emailId">Email ID</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="emailId"
                                        placeholder="Incture Email ID"
                                        value={UEmail}
                                        onChange={(e) => setUEmail(e.target.value)}
                                        disabled
                                    />
                                </div>
                                <div className="form-group col-md-6 pr-0">
                                    <label htmlFor="altEmailId">Alternate Email ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="altEmailId"
                                        placeholder="Enter your alternate email ID"
                                        value={alternateEmail}
                                        onChange={(e) => setAlternateEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row settings-user-details">
                        <div className="form-group col-md-12 p-0">
                            <label htmlFor="bio">About Yourself <small className="text-light">(Max Length: 500 chars)</small></label>
                            <textarea
                                className="form-control"
                                id="bio"
                                placeholder="Write about your personality or work experience"
                                rows="4"
                                value={UBio}
                                onChange={(e) => setUBio(e.target.value)}
                                maxLength="500"
                            ></textarea>
                        </div>
                    </div>
                    <div className="form-row settings-user-details">
                        <div className="form-group col-md-8 pl-0">
                            <label htmlFor="expertise">Field of Expertise <small className="text-light">(separated by comma)</small></label>
                            <input
                                type="text"
                                className="form-control"
                                id="expertise"
                                placeholder="Enter your field of expertise"
                                value={fieldOfExpertise}
                                onChange={(e) => setFieldOfExpertise(e.target.value)}
                                maxLength="100"
                            />
                        </div>
                        <div className="form-group col-md-4 pr-0">
                            <label htmlFor="phoneNo">Phone Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="phoneNo"
                                placeholder="Enter your phone number"
                                value={phoneNum}
                                onChange={handleChange}
                                maxLength="10"
                            />
                        </div>
                    </div>
                    <div className="form-row settings-user-details">
                        <div className="form-group col-md-6 pl-0">
                            <label htmlFor="linkedin">LinkedIn</label>
                            <input
                                type="text"
                                className="form-control"
                                id="linkedin"
                                placeholder="https://www.linkedin.com/"
                                pattern="(ftp|http|https):\/\/?(?:www\.)?linkedin.com/.*"
                                value={linkedinUrl}
                                onChange={(e) => setLinkedinUrl(e.target.value)}
                            />
                        </div>
                        <div className="form-group col-md-6 pr-0">
                            <label htmlFor="github">GitHub</label>
                            <input
                                type="text"
                                className="form-control"
                                id="github"
                                placeholder="https://www.github.com/"
                                pattern="(ftp|http|https):\/\/?(?:www\.)?github.com/.*"
                                value={githubUrl}
                                onChange={(e) => setGithubUrl(e.target.value)}
                            />
                        </div>
                    </div>
                    {/* <div className="form-row">
                            <div className="form-group col-md-5 pl-0">
                                <label htmlFor="currPass">Current Password</label>
                                    <input type="password" className="form-control" id="currPass" placeholder="Current Password" />
                                </div>
                            <div className="form-group col-md-5 pr-0">
                                <label htmlFor="newPass">New Password</label>
                                    <input type="password" className="form-control" id="newPass" placeholder="New Password"/>
                            </div>
                            <div className="form-group col-md-2 pr-0 d-flex align-items-end text-center justify-content-center">
                                <button type="submit" className="btn chg-btn">Change</button>
                            </div>
                    </div> */}

                    <button type="submit" className={isLoading ? "btn btn-sm upload-btn update disabled" : "btn btn-sm upload-btn update"}>{isLoading ? "Updating..." : "Update"}</button>
                </form>}
        </div>
    );
}

export default ProfileSettings;