import { Link, Route, Routes } from 'react-router-dom';
import MyActivity from './Myactivity';
import { useState } from 'react';
import Applicationanalytics from './Applicationanalytics';
import OnlineUser from './Onlineuser';
import Monthlead from './Quarterlyleader';

  
const Analytics = () => {
    const [isMysctivity, setIsMyactivity] = useState(true);
    const [isApplicationanalytics, setIsApplicationanalytics] = useState(false);
    //const [isOnlineuser, setIsOnlineuser] = useState(false);
    const [isQuarterlyleader, setIsQuarterleader]= useState(false)  

    const toggleButton = (page) => {
        if (page==="My activity") {
            setIsMyactivity(true);
            setIsApplicationanalytics(false);
            setIsQuarterleader(false);
        }
        else if (page==="Application Analytics"){
            setIsApplicationanalytics(true);
            setIsQuarterleader(false);
            setIsMyactivity(false);
        }
        else{
          setIsQuarterleader(true);
          setIsMyactivity(false);
          setIsApplicationanalytics(false);

        }
    }

    return ( 
        <div className="analytics">
          <p className="h3 headtxt">Analytics</p>
          <div className="btnGroup" role="group">
              <Link to="/home/analytics/myactivity">
                <button
                  onClick={() => toggleButton("My activity")}
                  type="button"
                  className={
                    "btn headBtn " + (isMysctivity ? "active-option" : "")
                  }
                >
                  <i className="fa fa-calendar-plus-o"></i>&nbsp; My Analytics
                </button>
              </Link>
              <Link to="/home/analytics/applicationanalytics">
                <button
                  onClick={() => toggleButton("Application Analytics")}
                  type="button"
                  className={"btn headBtn " + (isApplicationanalytics ? "active-option" : "")}
                >
                  <i className="fa fa-star"></i>&nbsp; Application Analytics
                </button>
              </Link>
              <Link to="/home/analytics/monthlyleader">
                <button
                  onClick={() => toggleButton("Monthly leader")}
                  type="button"
                  className={"btn headBtn " + (isQuarterlyleader ? "active-option" : "")}
                >
                  <i className="fa fa-trophy"></i>&nbsp; Leader of Month
                </button>
              </Link>
              </div>
            <div className="container" id="cards">
                <Routes>
                    <Route path="/" element={<MyActivity />} />
                </Routes>
                <Routes>
                    <Route path="/myactivity" element={<MyActivity />} />
                </Routes>
                <Routes>
                    <Route path="/applicationanalytics" element={<Applicationanalytics />} />
                </Routes>
                <Routes>
                    <Route path="/monthlyleader" element={<Monthlead />} />
                </Routes>
            </div>
    </div>
        
     );
}
 
export default Analytics;

