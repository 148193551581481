import { useState, useEffect } from "react";
import Moment from 'moment';

const AnnouncementPanel = () => {
  const [announcementBody, setAnnouncementBody] = useState(null);
  // const [announcementdate, setAnnouncementDate] = useState(null);
  const [isNull, setIsNull] = useState(true);

  useEffect(() => {
    fetchAnnouncements();
  }, [isNull]);

 

  function fetchAnnouncements() {
    fetch("https://reuseportalbackendservice.cherrywork.com/getOneAnnouncement")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAnnouncementBody(data.announcement);
        // var Date = data.date;
        // setAnnouncementDate(Moment(Date).format('DD-MMM-YYYY'));

        
      });
  }
  //announcementdate = Moment().format('DD-MMM-YYYY')

  return (
    <div className="nullCheck">
      {announcementBody && (
        <div className="d-flex flex-row flex-grow-1 flex-fill justify-content-center py-2 px-1 news">
          <marquee
            behavior="scroll"
            direction="left"
            className="news-scroll text-center"
          >
            <a className="announcement-news-scroll" href="#"><i className="fa fa-bullhorn"></i>  {announcementBody}</a>
          </marquee>
        </div>
      )}
    </div>
  );
};

export default AnnouncementPanel;
