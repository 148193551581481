import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const ComponentDelete = ({initialLoad}) => {

    const navigate = useNavigate();
    const { id } = useParams();

    const [singleComponent, setSingleComponent] = useState(null)
    
    useEffect(() => {

        fetch('https://reuseportalbackendservice.cherrywork.com/cardDetails/component/cid/'+id)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            if (data.userEmail === sessionStorage.getItem("loggedInEmail")  || (sessionStorage.getItem("loggedInRole") === "Admin") ) {

                if (window.confirm("Are you sure you want to delete the component?")) {

                    fetch("https://reuseportalbackendservice.cherrywork.com/deleteFiles/id/"+id,{
                        method: "DELETE"
                    })
                    .then((res) => {
                        initialLoad();
                        navigate('/home/mycomponents');
                      })
                    } else {
                        navigate('/home/');
                    }
                    
            }
            else{
                navigate('/home/component/'+id);
            }
            
        })

    },[])


    return(
        <div className="deleteMsg d-flex justify-content-center align-items-center">
            <p className="lead">Deleting Component...</p>
        </div>
            
    );
     
}
 
export default ComponentDelete;