import { useState, useEffect, Suspense } from "react";
import ComponentView from "../Common/ComponentView";
import LoadingSpinner from "../Util/LoadingSpinner";

const MostLiked = ({view, components, loadMore, limit, num, isLoading}) => {

    return ( 
        <>
        {isLoading ? <LoadingSpinner className="m-auto" /> :
            <div className="row">
                {/* { components && components.map((component)=>(
                <div className="col-md-6" key={component.component_id}>
                        <Suspense fallback={<div>Loading</div>}>
                            <Card  component={component} />
                        </Suspense>
            
                    
                    </div>
                    ))
                }  */}

                
                    { components && components.slice(0,limit).map((component)=>(
                            <ComponentView key={component.component_id} component={component} view={view}/>
                            // <ListItem key={component.component_id} component={component} />
                        ))
                    }
            </div>
        }
        
        { (components.length >= limit) &&
            <div className="row">
                <button onClick={()=>{loadMore(num+1)}} className="btn btn-sm text-light m-auto"><strong>Load More <i className="fa fa-refresh"></i></strong></button>
            </div>

            }
        </>
     );
}
 
export default MostLiked;