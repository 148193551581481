import MyDownloads from "./MyDownloads";
import MyUploads from "./MyUploads";
import { Link, Route, Routes } from 'react-router-dom';
import { useState } from "react";

const MyComponents = () => {
    const [isUploads, setIsUploads] = useState(true)

    const toggleButton = (page) => {
        if (page==="uploads") {
            setIsUploads(true)
        }
        else{
            setIsUploads(false)
        }
    }

    return (
      <div className="components">
          
            <p className="h3 headtxt">My Components</p>
            <div className="btnGroup" role="group">
                <Link to="/home/mycomponents/uploads"><button onClick={() => toggleButton("uploads")} type="button" className={"btn headBtn "+(isUploads?"active-option":"")}><i className="fa fa-upload"></i>&nbsp;My Uploads</button></Link>
                <Link to="/home/mycomponents/downloads"><button onClick={() => toggleButton("downloads")} type="button" className={"btn headBtn "+(!isUploads?"active-option":"")}><i className="fa fa-download"></i>&nbsp;My Downloads</button></Link>
            </div>
        <div className="container" id="cards">


                    <Routes>
                            <Route path="/" element={<MyUploads/>}/>
                    </Routes>
                    <Routes>
                            <Route path="/uploads" element={<MyUploads/>}/>
                    </Routes>
                    <Routes>
                        <Route path="/downloads" element={<MyDownloads/>}/>
                    </Routes>
        
      </div>

        </div>
    );
}

export default MyComponents;