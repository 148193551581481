import Card from "../Common/Card";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const MyDownloads = () => {
    const [downloadFiles,setDownloadFiles] =  useState([]);
    const [isDownloadFiles,setIsDownloadFiles] =  useState(true);

    useEffect(() => {

        fetch('https://reuseportalbackendservice.cherrywork.com/myDownloads/component/email/'+sessionStorage.getItem("loggedInEmail"))
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setDownloadFiles(data);
            if (data.length===0) {
                setIsDownloadFiles(true);
            }
            else{
                setIsDownloadFiles(false);
            }
        })

    },[])

    return (
        <div className="row">
            { downloadFiles && downloadFiles.map((component)=>(
                            <Card  key={component.component_id} component={component} />                                   
                ))
            }
            { isDownloadFiles && <p className=" error-message ">No Components Downloaded Yet.</p> } 
        </div>
        
     );
}
 
export default MyDownloads;