import { useState, useEffect } from "react";
import { Link } from "react-router-dom";


const Comments = ({cid,nComments}) => {

    const [comments, setComments] = useState(false);
    const [state, setState] = useState(false);
    const [listComments, setListComments] = useState([]);

    useEffect(() => {

        fetch('https://reuseportalbackendservice.cherrywork.com/comments/cid/'+cid)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setListComments(data.reverse());
        })

    },[cid,nComments])


    const toggle = () => {
        setState(!state);
    }

    const deleteComment = (id) => {
        fetch('https://reuseportalbackendservice.cherrywork.com/delete/id/'+id,{
            method: "DELETE"
        })
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            
            if (data.status === "1") {
                const newComments = listComments.filter(comment => comment.comment_id!==id);
                setListComments(newComments);
            }
        })
    }

    return (
        <div className="comment-section">
            {comments && listComments.map((comment)=>(
                <div key={comment.comment_id} className="row p-1">
                    <div className="col-md-12 pl-4 pr-4">
                        <div className="top-comment flex-grow-1">
                            <div className="d-flex flex-row user-info">
                                <div className="d-flex flex-column justify-content-start">
                                        <span className="name mb-0">
                                            <Link to={"/profile/"+comment.email}>{comment.name}</Link>&nbsp;&nbsp;
                                            {
                                                (sessionStorage.getItem("loggedInEmail") === comment.email) && 
                                                <i onClick={() => deleteComment(comment.comment_id)} className="fa fa-trash text-danger comment-del"></i>
                                            }
                                        </span>
                                    
                                    <span className="small text-secondary m-0 mt-1">{comment.date.slice(8,10) +"-"+comment.date.slice(5,7)+"-"+comment.date.slice(0,4)}</span></div>
                            </div>
                            <div className="mt-2">
                                <p className="comment-body">{comment.comment}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className='showcomments' onClick={toggle}>
                <a onClick={() => setComments(!comments)} id="toggle-comments" className={'toggle--button ' + (state ? 'toggle--close' : '')}>{state ? 'Hide Comments' : 'Show All Comments'}</a>
            </div>
        </div>

    );
};

export default Comments;